<script>
import { deleteApi, getAll } from "@/services/escolas-service";

export default {
  components: {
    "form-escolas": () => import("@/views/Escolas/FormEscolas"),
  },
  created() {
    this.getEscolas();
  },
  data: () => {
    return {
      dialogForm: false,
      headers: [
        { text: "Nome da Escola", value: "name" },
        {
          text: "Ações",
          value: "actions",
          sortable: false,
          align: "start",
          width: 200,
        },
      ],
      escolas: [],
      selectedEscola: undefined,
    };
  },
  methods: {
    createdForm(escola) {
      this.escolas.push(escola);
      this.dialogForm = false;
    },
    async deleteEscola(escola) {
      try {
        this.$store.dispatch("loading/openDialog");

        await deleteApi(escola);

        this.escolas = this.escolas.filter((e) => e.id !== escola.id);

        this.$toasted.global.defaultSuccess();
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    updatedForm(escolaEdited) {
      const index = this.escolas.findIndex(
        (escola) => escola.id === escolaEdited.id
      );

      const newEscolas = [...this.escolas];
      newEscolas[index] = escolaEdited;

      this.escolas = newEscolas;
      this.dialogForm = false;
    },
    openModal(escola) {
      this.selectedEscola = escola;
      this.dialogForm = true;
    },
    closeModal() {
      this.dialogForm = false;
      this.selectedEscola = undefined;
    },
    async getEscolas() {
      try {
        this.$store.dispatch("loading/openDialog");

        const response = await getAll();

        this.escolas = response.data;
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
  },
};
</script>

<template>
  <v-container>
    <perseu-card title="Escolas">
      <template slot="title-right">
        <v-btn @click="openModal()" color="secondary"> Nova Escola </v-btn>
      </template>

      <v-flex slot="content">
        <v-data-table :items="escolas" :headers="headers" class="elevation-1">
          <template v-slot:item.actions="{ item }">
            <table-action-button
              @click="openModal(item)"
              text="Editar"
              icon="edit"
            />

            <table-action-button
              @click="deleteEscola(item)"
              text="Desativar"
              icon="delete"
            />
          </template>
        </v-data-table>
      </v-flex>
    </perseu-card>

    <v-dialog v-if="dialogForm" v-model="dialogForm" max-width="600">
      <form-escolas
        :selectedEscola="selectedEscola"
        @created="createdForm"
        @updated="updatedForm"
        @closed="closeModal"
      />
    </v-dialog>
  </v-container>
</template>
